import React, { useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
const Contactus = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  useEffect(() => {
    // Check if the page has been reloaded
    const isReload = sessionStorage.getItem('isReloaded');
    window.scrollTo(0, 0);
    if (!isReload) {
      // If it's the first load, reload the page and set the flag
      sessionStorage.setItem('isReloaded', 'true');
      window.scrollTo(0, 0);
      window.location.reload(); // Reload the page
    }

    // If the page has been reloaded, the component continues as normal
  }, []);
  return (
    <>

      <section className="contact-us section">
        <ToastContainer
          position="top-center"
          theme="dark"
        />
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-us-left">
                  {/*Start Google-map */}
                  <div id="myMap" />
                  {/*/End Google-map */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-us-form">
                  <h2>Contact With Us</h2>
                  <p>
                    If you have any questions please fell free to contact with us.
                  </p>
                  {/* Form */}
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      subject: "",
                      message: "",
                      news: false, // Checkbox for newsletter
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      // values.preventDefault();
                      const response = await axios.post('https://api.swasthacare.in/api/auth/contactUs', values);
                      // console.log("Form Data:", values);
                      console.log(response.data)
                      toast(response?.data?.message);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form className="form">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                type="text"
                                name="name"
                                placeholder="Name"
                                className="form-control"
                              />
                              <ErrorMessage name="name" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="form-control"
                              />
                              <ErrorMessage name="email" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                className="form-control"
                              />
                              <ErrorMessage name="phone" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                className="form-control"
                              />
                              <ErrorMessage name="subject" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <Field
                                as="textarea"
                                name="message"
                                placeholder="Your Message"
                                className="form-control"
                              />
                              <ErrorMessage name="message" component="div" className="text-danger" />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group login-btn">
                              <button className="btn" type="submit" disabled={isSubmitting}>
                                Send
                              </button>
                            </div>
                            <div className="checkbox">
                              <label className="checkbox-inline" htmlFor="news">
                                <Field name="news" type="checkbox" id="news" />
                                Subscribe to our Newsletter and stay updated with all the latest
                                updates about health and fitness.
                              </label>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {/*/ End Form */}
                </div>
              </div>
            </div>
          </div>
          <div className="contact-info">
            <div className="row">
              {/* single-info */}
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-ui-call" />
                  <div className="content">
                    <h3>+91 8688344719</h3>
                    <p>info@swasthacare.in</p>
                  </div>
                </div>
              </div>
              {/*/End single-info */}
              {/* single-info */}
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont-google-map" />
                  <div className="content">
                    <h3>Follow us on Social media by clicking here</h3>
                    <p>Instagram, Facebook, Twitter, Whatsapp</p>
                  </div>
                </div>
              </div>
              {/*/End single-info */}
              {/* single-info */}
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-wall-clock" />
                  <div className="content">
                    <h3>Click here to join our whatsapp community and hear about offers and deals</h3>
                    <p></p>
                  </div>
                </div>
              </div>
              {/*/End single-info */}
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Contactus