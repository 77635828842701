import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Blogs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <section className="blog section" id="blog">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>Keep up with Our Most Recent Medical News.</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
              This page will help you to get important tips to stay fit and follow a routine.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12">
          {/* Single Blog */}
          <div className="single-news">
            <div className="news-head">
              <img src="img/blog1.jpg" alt="#" />
            </div>
            <div className="news-body">
              <div className="news-content">
                <div className="date">Nov 15th, 2024</div>
                <h2>
                  <a>
                    We have annnocuced our new product.
                  </a>
                </h2>
                <p className="text">
                  Enter sample text related to this article when needed
                </p>
              </div>
            </div>
          </div>
          {/* End Single Blog */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Single Blog */}
          <div className="single-news">
            <div className="news-head">
              <img src="img/blog2.jpg" alt="#" />
            </div>
            <div className="news-body">
              <div className="news-content">
                <div className="date">10th Nov, 2024</div>
                <h2>
                  <a>
                    Top five way for solving teeth problems.
                  </a>
                </h2>
                <p className="text">
                Enter sample text related to this article when needed
                </p>
              </div>
            </div>
          </div>
          {/* End Single Blog */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Single Blog */}
          <div className="single-news">
            <div className="news-head">
              <img src="img/blog3.jpg" alt="#" />
            </div>
            <div className="news-body">
              <div className="news-content">
                <div className="date">05 Jan, 2020</div>
                <h2>
                  <a >
                    5 things to add to your meal to keep your gut healthy
                  </a>
                </h2>
                <p className="text">
                Enter sample text related to this article when needed
                </p>
              </div>
            </div>
          </div>
          {/* End Single Blog */}
        </div>
      </div>
    </div>
  </section>
  )
}

export default Blogs