import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [activeTab, setActiveTab] = useState(''); // Initial active tab
    const navigate = useNavigate();

    const handleNavigation = (route) => {
        navigate(`/${route}`);
        setActiveTab(route); // Update active tab
    };

    return (
        <header className="header">
            <div className="header-inner">
                <div className="container">
                    <div className="inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-12">
                                <div  onClick={() => handleNavigation('')} className="logo">
                                    <a>
                                        <img width={'100%'} src="img/logo.png" alt="#" />
                                    </a>
                                </div>
                                <div className="mobile-nav" />
                            </div>
                            <div className="col-lg-7 col-md-9 col-12">
                                <div className="main-menu">
                                    <nav className="navigation">
                                        <ul className="nav menu">
                                            <li
                                                onClick={() => handleNavigation('')}
                                                className={activeTab === '' ? 'active' : ''}
                                            >
                                                <a>Home</a>
                                            </li>
                                            <li
                                                onClick={() => handleNavigation('services')}
                                                className={activeTab === 'services' ? 'active' : ''}
                                            >
                                                <a>Services</a>
                                            </li>
                                            <li
                                                onClick={() => handleNavigation('blogs')}
                                                className={activeTab === 'blogs' ? 'active' : ''}
                                            >
                                                <a>Blogs</a>
                                            </li>
                                            {/* <li
                                                onClick={() => handleNavigation('aboutus')}
                                                className={activeTab === 'aboutus' ? 'active' : ''}
                                            >
                                                <a>About Us</a>
                                            </li> */}
                                            <li
                                                onClick={() => handleNavigation('contactus')}
                                                className={activeTab === 'contactus' ? 'active' : ''}
                                            >
                                                <a>Contact Us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="get-quote">
                                    <a className="btn">Download App</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
