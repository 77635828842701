import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Services = () => {
  const { pathname } = useLocation();

   useEffect(()=>{
    window.scrollTo(0, 0);
    sessionStorage.clear()
   },[])
  return (
    <section className="services section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>We Offer Different Services To Improve Your Health</h2>
            <img src="img/section-img.png" alt="#" />
            <p>
              
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-prescription" />
            <h4>
              <a    >General Treatment:</a>
            </h4>
            <p>
              General treatment includes visiting general physician visit & routine checkups.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-tooth" />
            <h4>
              <a    >Teeth Whitening:</a>
            </h4>
            <p>
            There are several reasons why you might want to whiten your teeth, including:
            Health, Convenience, Safety, Appereance, Professionalism.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-heart-alt" />
            <h4>
              <a    >Heart Surgery:</a>
            </h4>
            <p>
            Heart surgery, also known as cardiovascular surgery, can treat a variety of heart conditions. Some common types of heart surgery are 
            Open-heart surgery, Minimally invasive surgery, Valvular surgery, Myectomy, Transmyocardial revascularization.{" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-listening" />
            <h4>
              <a    >Ear Treatment include:
                
              </a>
            </h4>
            <p>
            Pain Medication, Eardrops, Antibiotics, Ear irrigation, Autoinsufflation. {" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-eye-alt" />
            <h4>
              <a    >Eye/Vision Treatment:</a>
            </h4>
            <p>
              Eye treatment includes various methods such as plumping, smoothing, giving extra hydration. {" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* Start Single Service */}
          <div className="single-service">
            <i className="icofont icofont-blood" />
            <h4>
              <a    >Blood Transfusion:</a>
            </h4>
            <p>
              Blood Transfusion is a medical procedure that involves giving healthy blood to a patient through intravenous(IV) line to replace lost blood components. {" "}
            </p>
          </div>
          {/* End Single Service */}
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services