import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const TermsAndConditions = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div className='termsAndConditions'>
            <h1 style={{ textAlign: 'center' }}>SwasthaCare Terms & Conditions</h1>
            <div style={{margin:'10px'}}>
                <h4>Personal Data We Collect</h4>
                <p>
                    We gather personal data from you directly or through third parties who have your consent or a legal right to share it. These third-party sources may include advertising platforms, partners, and data aggregators. The personal data we collect may involve information about you, your interests, the games and services you use, demographic details, and general location information, all used per our Privacy Policy.
                </p>
            </div>
            <div style={{margin:'10px'}}>
                <h4>Directly Collected Personal Data</h4>
                <p>
                    When you interact with our services, we may collect personal information directly from you, which is stored on our systems for the purposes outlined in our Privacy Policy. The exact data collected depends on the services you engage with and may include:
                </p>
                <ul>
                    <li>Your first and last name</li>
                    <li>Your gaming username or alias</li>
                    <li>Email address and other contact details</li>
                    <li>Communications within the services (e.g., messages and in-game chats)</li>
                    <li>Information you provide when contacting customer support or applying for a job through our website.</li>
                </ul>
            </div>
            <div style={{margin:'10px'}}>
                <h4>Depending on the services you use, we may also collect:</h4>

                <p><span>Device Data:</span> Information related to your device, including unique identifiers like IDFA and IDFV.</p>
                <p><span>Analytics Data:</span>Details about your interactions with our games and services, including session duration, device type, features used, in-game spending, levels achieved, and time spent viewing advertisements.</p>
                <p><span>Location Data:</span>Approximate location derived from your IP address.</p>
                <p><span>Financial Data:</span>Limited information regarding in-app purchases made through third-party platforms (e.g., Apple or Google stores). We don’t receive your financial details but may receive non-financial data like your name and purchased items for order fulfillment.</p>
            </div>
            <div style={{margin:'10px'}}>
                <h4>
                    Data from Third Parties
                </h4>

                <p>
                    We may obtain information from third-party sources, including app stores when you download, purchase, or play our games. Certain features might allow you to log in through social networks like Facebook, providing us with personal data such as your name, email, user ID, profile picture, and the names of friends who play our games. The information we receive depends on the privacy settings of these platforms. If you log in with Facebook, we do not have the ability to post on your page without your permission and won’t see information about friends who aren’t using our games. If you wish to keep your game progress private from your Facebook friends, you can disconnect your account in our games.
                </p>
            </div>
            <div style={{margin:'10px'}}>
            <h4>Purposes for Processing Your Personal Data</h4>
            <p>
                We collect and use your personal data for several reasons, including:
            </p>
            <ul>
                <li>Optimizing games for your device and offering personalized in-game events and promotions.</li>
                <li>Operating, improving, and optimizing our games and services.</li>
                <li>Personalizing user experiences and understanding preferences.</li>
                <li>Granting access to specific features of our services.</li>
                <li>Managing organizational operations.</li>
                <li>Verifying your identity and entitlement to products/services.</li>
                <li>Conducting surveys for feedback.</li>
                <li>Facilitating player communication within games and on social media.</li>
                <li>Responding to inquiries and providing customer support.</li>
                <li>Preventing prohibited activities and protecting players.</li>
                <li>Measuring interest in our services.</li>
                <li>Ensuring quality control.</li>
                <li>Complying with legal obligations.</li>
                <li>Fulfilling contractual duties.</li>
                <li>Conducting internal research and analytics.</li>
                <li>Displaying advertisements for our games and third-party ads.</li>
                <li>Sending newsletters and marketing materials.</li>
                <li>Defending and exercising legal rights as per our Terms of Service.</li>
                <li>Other stated purposes in this Privacy Policy or as permitted by law.</li>
            </ul>

            <p>For individuals in the European Economic Area (EEA), we only collect and process personal data based on applicable EU laws, relying on the following </p>
            <h4>legal bases:</h4>
            <p><span>Legal Obligation:</span> Collecting data to meet legal requirements.</p>
            <p><span>Contractual Necessity:</span> Using data necessary for providing requested services.</p>
            <p><span>Legitimate Interests:</span> Collecting data for purposes expected within the context of your service use, such as marketing and analytics.</p>
            <p><span>Consent:</span> Relying on consent for specific data collection, such as device information. You can withdraw consent by uninstalling our games.</p>
            <p><span>Consent:</span> Relying on consent for specific data collection, such as device information. You can withdraw consent by uninstalling our games.</p>
            <p>If you continue using our services, we will still collect certain personal data as described, even if you opt out of some processing.</p>
        </div>
            <div style={{margin:'10px'}}>
                <h4>Disclosure of Your Personal Data</h4>
                <p>We may share your personal data with third parties as outlined in this Privacy Policy for service provision, legal compliance, and protection of our rights.</p>
                <h4>Business Transactions: </h4>


                <p>In cases of mergers or acquisitions, your information may be shared with the acquiring entity as part of the transition process.</p>
                <p>Law Enforcement: We may disclose your data if required by law or in response to valid requests from authorities.</p>
                <p>Other Legal Requirements: We may disclose data to comply with legal obligations, protect our rights, prevent wrongdoing, or ensure safety.</p>
                <p> Other Users: If you engage in online communities or make purchases, we may share your public profile information with other members.</p>
                <p> Third-Party Service Providers: We work with third-party partners for services such as analytics, hosting, customer support, and marketing, sharing your information as necessary. These partners may use cookies and similar technologies to collect data about your activity per their privacy policies.
                </p>
                <p>We also participate in interest-based advertising, allowing third-party advertising companies to serve targeted ads based on your browsing history. These partners may combine your data with information they’ve collected elsewhere to tailor advertising. While we use these services to enhance relevance, we don’t control how they utilize the data. We recommend reviewing the privacy policies of our partners for more information on their data handling practices.</p>
            </div>
        </div>
    )
}

export default TermsAndConditions